import "isomorphic-fetch";
import api from "./api";

const convertFileToGCSUrl = f => {
  const formData = new FormData();
  formData.append("file", f);
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  return api.post(`/admin/uploadImage`, formData, config);
};

// TODO: Update to V3 pattern & de-dupe code
// https://marmelab.com/blog/2019/10/14/react-admin-v3-data.html

const uploadEnhancedProvider = function(provider) {
  
  function create(resource, params) {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    if (resource === "brands") {
      if (params.data.articles) delete params.data.articles;
      if (params.data.offers) delete params.data.offers;

      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          if (
            params.data.logoUrl &&
            params.data.logoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(params.data.logoUrl.rawFile);
            result.data.logoUrl = link.data;
          }
          if (
            params.data.backgroundPhotoUrl &&
            params.data.backgroundPhotoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.backgroundPhotoUrl.rawFile
            );
            result.data.backgroundPhotoUrl = link.data;
          }
          resolve(provider.create(resource, result));
        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "services") {

      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          if (
            params.data.imageUrl &&
            params.data.imageUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(params.data.imageUrl.rawFile);
            result.data.imageUrl = link.data;
          }
          
          resolve(provider.create(resource, result));

        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "users") {
      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          if (
            params.data.profileImageUrl &&
            params.data.profileImageUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.profileImageUrl.rawFile
            );
            result.data.profileImageUrl = link.data;
          }
          if (params.data.pets) {
            if(params.data.pets.length > 0) {
              
              const petsWithNewPhotos = params.data.pets.filter(pet => {
                return  pet.photoUrl &&
                        pet.photoUrl.rawFile &&
                        pet.photoUrl.rawFile instanceof File
              });

              await Promise.all(
                petsWithNewPhotos.map(pet => {
                  return new Promise(async (resolve, reject) => {
                    try {
                      
                      const link = await convertFileToGCSUrl(
                        pet.photoUrl.rawFile
                      );
                      
                      // New: because the pet may not have an ID
                      const p = result.data.pets.find(p => p.photoUrl.rawFile === pet.photoUrl.rawFile);

                      // OLD: const p = result.data.pets.find(p => p.id === pet.id);

                      p.photoUrl = link.data;

                      resolve();
                    } catch (e) {
                      reject(e);
                    }
                  });
                })
              );

              // Medical Record Images
              for(let p = 0, pl = params.data.pets.length; p < pl; p++) {
                
                const pet = params.data.pets[p];

                if(pet.medicalRecords && Array.isArray(pet.medicalRecords)) {
                  for(let m = 0, ml = pet.medicalRecords.length; m < ml; m++) {
                    const record = pet.medicalRecords[m];
                    if(record.imageUrl &&
                      record.imageUrl.rawFile &&
                      record.imageUrl.rawFile instanceof File) {
                        // Has image for upload, do it in series to not lose your position
                        // TODO: This is hacky but the whole user pet model is like this.
                        const { data } = await convertFileToGCSUrl(record.imageUrl.rawFile);
                        record.imageUrl = data;
                      }
                  }
                }
              }

              // const petsWithNewMedicalRecords = params.data.pets.filter(pet => {
              //   return  pet.photoUrl &&
              //           pet.photoUrl.rawFile &&
              //           pet.photoUrl.rawFile instanceof File
              // });

              // await Promise.all(
              //   petsWithNewMedicalRecords.map(pet => {
              //     return new Promise(async (resolve, reject) => {
              //       try {
                      
              //         const link = await convertFileToGCSUrl(
              //           pet.photoUrl.rawFile
              //         );
                      
              //         const p = result.data.pets.find(p => p.id === pet.id);
                      
              //         p.photoUrl = link.data;

              //         resolve();
              //       } catch (e) {
              //         reject(e);
              //       }
              //     });
              //   })
              // );

            } else {
              result.data.pets = null;
            }
          }
          resolve(provider.create(resource, result));
        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "offers") {
      if (params.data.brand) delete params.data.brand;

      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          if (result) {
            if (result.data) {
              if (result.data.claimedCount) {
                delete result.data.claimedCount;
              }
            }
          }
          if (result) {
            if (result.data) {
              if (result.data.brandName) {
                delete result.data.brandName;
              }
            }
          }
          if (
            params.data.photoUrl &&
            params.data.photoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.photoUrl.rawFile
            );
            result.data.photoUrl = link.data;
          }
          if (
            params.data.productLogoUrl &&
            params.data.productLogoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.productLogoUrl.rawFile
            );
            result.data.productLogoUrl = link.data;
          }
          resolve(provider.create(resource, result));
        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "content") {
      if (
        params.data.photoUrl &&
        params.data.photoUrl.rawFile instanceof File
      ) {
        return new Promise(async (resolve, reject) => {
          try {
            const link = await convertFileToGCSUrl(
              params.data.photoUrl.rawFile
            );
            resolve(
              provider.create(resource, {
                ...params,
                data: {
                  ...params.data,
                  photoUrl: link.data
                }
              })
            );
          } catch (e) {
            reject(e);
          }
        });
      }
    }
    if (resource === "adverts") {
      if (params.data.image && params.data.image.rawFile instanceof File) {
        return new Promise(async (resolve, reject) => {
          try {
            const link = await convertFileToGCSUrl(params.data.image.rawFile);
            resolve(
              provider.create(resource, {
                ...params,
                data: {
                  ...params.data,
                  image: link.data
                }
              })
            );
          } catch (e) {
            reject(e);
          }
        });
      }
    }
    return provider.create(resource, params);
  }

  function update(resource, params) {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    if (resource === "brands") {
      if (params.data.articles) delete params.data.articles;
      if (params.data.offers) delete params.data.offers;

      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          if (
            params.data.logoUrl &&
            params.data.logoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(params.data.logoUrl.rawFile);
            result.data.logoUrl = link.data;
          }
          if (
            params.data.backgroundPhotoUrl &&
            params.data.backgroundPhotoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.backgroundPhotoUrl.rawFile
            );
            result.data.backgroundPhotoUrl = link.data;
          }
          resolve(provider.update(resource, result));
        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "services") {

      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          if (
            params.data.imageUrl &&
            params.data.imageUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(params.data.imageUrl.rawFile);
            result.data.imageUrl = link.data;
          }
          
          resolve(provider.update(resource, result));

        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "users") {
      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          
          // User profile image
          if (
            params.data.profileImageUrl &&
            params.data.profileImageUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.profileImageUrl.rawFile
            );
            result.data.profileImageUrl = link.data;
          }

          // Parsing pets
          if (params.data.pets) {
            if(params.data.pets.length > 0) {
              
              // Get all pets with new images for upload
              const petsWithNewPhotos = params.data.pets.filter(pet => {
                return  pet.photoUrl &&
                        pet.photoUrl.rawFile &&
                        pet.photoUrl.rawFile instanceof File
              });

              await Promise.all(
                petsWithNewPhotos.map(pet => {
                  return new Promise(async (resolve, reject) => {
                    try {
                      
                      const link = await convertFileToGCSUrl(
                        pet.photoUrl.rawFile
                      );
                      
                      // New: because the pet may not have an ID
                      const p = result.data.pets.find(p => p.photoUrl.rawFile === pet.photoUrl.rawFile);

                      // OLD: const p = result.data.pets.find(p => p.id === pet.id);

                      p.photoUrl = link.data;

                      resolve();
                    } catch (e) {
                      reject(e);
                    }
                  });
                })
              );

              // Medical Record Images
              for(let p = 0, pl = params.data.pets.length; p < pl; p++) {
                
                const pet = params.data.pets[p];

                if(pet.medicalRecords && Array.isArray(pet.medicalRecords)) {
                  for(let m = 0, ml = pet.medicalRecords.length; m < ml; m++) {
                    const record = pet.medicalRecords[m];
                    if(record.imageUrl &&
                      record.imageUrl.rawFile &&
                      record.imageUrl.rawFile instanceof File) {
                        // Has image for upload, do it in series to not lose your position
                        // TODO: This is hacky but the whole user pet model is like this.
                        const { data } = await convertFileToGCSUrl(record.imageUrl.rawFile);
                        record.imageUrl = data;
                      }
                  }
                }
              }

            } else {
              result.data.pets = null;
            }
          }
          resolve(provider.update(resource, result));
        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "offers") {
      if (params.data.brand) delete params.data.brand;

      return new Promise(async (resolve, reject) => {
        try {
          const result = {
            ...params,
            data: {
              ...params.data
            }
          };
          if (result) {
            if (result.data) {
              if (result.data.claimedCount) {
                delete result.data.claimedCount;
              }
            }
          }
          if (result) {
            if (result.data) {
              if (result.data.brandName) {
                delete result.data.brandName;
              }
            }
          }
          if (
            params.data.photoUrl &&
            params.data.photoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.photoUrl.rawFile
            );
            result.data.photoUrl = link.data;
          }
          if (
            params.data.productLogoUrl &&
            params.data.productLogoUrl.rawFile instanceof File
          ) {
            const link = await convertFileToGCSUrl(
              params.data.productLogoUrl.rawFile
            );
            result.data.productLogoUrl = link.data;
          }
          resolve(provider.update(resource, result));
        } catch (e) {
          reject(e);
        }
      });
    }
    if (resource === "content") {
      if (
        params.data.photoUrl &&
        params.data.photoUrl.rawFile instanceof File
      ) {
        return new Promise(async (resolve, reject) => {
          try {
            const link = await convertFileToGCSUrl(
              params.data.photoUrl.rawFile
            );
            resolve(
              provider.update(resource, {
                ...params,
                data: {
                  ...params.data,
                  photoUrl: link.data
                }
              })
            );
          } catch (e) {
            reject(e);
          }
        });
      }
    }
    if (resource === "adverts") {
      if (params.data.image && params.data.image.rawFile instanceof File) {
        return new Promise(async (resolve, reject) => {
          try {
            const link = await convertFileToGCSUrl(params.data.image.rawFile);
            resolve(
              provider.update(resource, {
                ...params,
                data: {
                  ...params.data,
                  image: link.data
                }
              })
            );
          } catch (e) {
            reject(e);
          }
        });
      }
    }
    return provider.update(resource, params);
  }
  
  return {
    ...provider,
    update: update,
    create: create
  }
}

export default uploadEnhancedProvider;
