import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  SimpleShowLayout,
  Show,
  ImageField,
  ImageInput,
} from "react-admin";

import formatImageInput from '../formatters/imageFormatter';

const ServicesReferenceShow = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <TextField source="title" />
        <ImageField source="imageUrl" />
      </SimpleShowLayout>
    </Show>
  );
};

export const ServicesList = props => (
  <List {...props}>
    <Datagrid expand={<ServicesReferenceShow />}>
      <TextField source="id" />
      <TextField source="title" />
      <EditButton />
    </Datagrid>
  </List>
);
export const ServicesEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="title" />
      <ImageInput
        source="imageUrl"
        label="Service Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
export const ServicesCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <ImageInput
        source="imageUrl"
        label="Service Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
export const ServicesShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <ImageField source="imageUrl" />
    </SimpleShowLayout>
  </Show>
);
