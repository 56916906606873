import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "react-admin";
import fetchOptions from "./fetchOptions";
import host from "./adminHost";
import uploadEnhancedProvider from "./addUploadFeature";

const httpClient = (url, options = {}) => {
  fetchOptions(options);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(host, httpClient);
const uploadCapableDataProvider = uploadEnhancedProvider(dataProvider);
export default uploadCapableDataProvider;
