import React, { Component } from 'react';

export default class Logo extends Component {
  render() {
    return (
      <img src="logo-bw-horizontal.png" alt="logo" style={{
        height: 40
      }} />
    );
  }
}
