import TextField from "@material-ui/core/TextField";
import React from "react";

const renderTextInput = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    error={!!(touched && error)}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);
export default renderTextInput;
