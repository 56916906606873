// in SubGenreInput.js
import React, { Component } from "react";
import * as _ from "lodash";
import { SelectInput, Loading } from "react-admin";
import api from "../api";

class SubGenreInput extends Component {
  state = {
    subgenres: []
  };

  // This is necessary so that the SelectInput receive all its required props
  // from react-final-form
  static defaultProps = { addField: true };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dependsOnValue !== this.props.dependsOnValue) {
      this.fetchData(nextProps);
    }
  }

  fetchData(props) {
    api.get(`/admin/${props.dependsOnValue}`).then(response => {
      this.setState({ subgenres: response.data });
    });
  }

  render() {
    return this.state.subgenres.length >=0 ? (
      <SelectInput
        {..._.omit(this.props, ["dependsOnValue"])}
        choices={this.state.subgenres}
      />
    ) : (
      <Loading />
    );
  }
}

SubGenreInput.propTypes = SelectInput.propTypes;
SubGenreInput.defaultProps = SelectInput.defaultProps;

export default SubGenreInput;
