import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  SimpleShowLayout,
  Show,
  ImageField,
  ImageInput,
} from "react-admin";

import formatImageInput from '../formatters/imageFormatter';

const AdvertsReferenceShow = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <ImageField source="image" title="Photo" />
      </SimpleShowLayout>
    </Show>
  );
};

export const AdvertsList = props => (
  <List {...props}>
    <Datagrid expand={<AdvertsReferenceShow />}>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="backgroundColor" />
      <TextField source="externalLink" />
      <TextField source="position" />
      <EditButton />
    </Datagrid>
  </List>
);
export const AdvertsEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="title" />
      <TextInput source="backgroundColor" />
      <TextInput source="externalLink" />
      <TextInput source="position" />
      <ImageInput
        source="image"
        label="Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
export const AdvertsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="backgroundColor" />
      <TextInput source="externalLink" />
      <TextInput source="position" />
      <ImageInput
        source="image"
        label="Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
export const AdvertsShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="backgroundColor" />
      <TextField source="externalLink" />
      <TextField source="position" />
      <ImageField source="image" title="Photo" />
    </SimpleShowLayout>
  </Show>
);
