// in src/Dashboard.js
import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardContent, Grid } from "@material-ui/core";
import countEntities from "../common/countEntities";

export default () => {
  const [totals, setTotals] = useState({});

  useEffect(() => {
    countEntities()
      .then(data => data.data)
      .then(data => {
        setTotals(data);
      });
  }, []);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={3}>
        <Card>
          <CardHeader title="Brands" />
          <CardContent>Number of entities: {totals.brandsTotal}</CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card>
          <CardHeader title="Offers" />
          <CardContent>Number of entities: {totals.offersTotal}</CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card>
          <CardHeader title="Content" />
          <CardContent>Number of entities: {totals.contentTotal}</CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card>
          <CardHeader title="Users" />
          <CardContent>Number of entities: {totals.usersTotal}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
