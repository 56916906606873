// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import { Title, downloadCSV, useAuthenticated } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import GetAppIcon from "@material-ui/icons/GetApp";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Paper from "@material-ui/core/Paper";
import Popover from '@material-ui/core/Popover';

import { DateRangePicker } from 'react-date-range';
import { subDays, endOfDay, startOfDay, format } from 'date-fns';

import jsonExport from "jsonexport";

import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import api from "../api";

import TimeLineChart from '../layout/TimeLineChart';

import getAnalyticsByBrandId from "../common/getAnalyticsByBrandId";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: 10,
  },
  paper: {
    padding: 15,
  },
  loader: {
    display: 'block',
    margin: 'auto',
    padding: 20
  },
  detailsTable: {
    width: '100%',
    lineHeight: '2em',
  },
  idCell: {
    width: '70px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  image: {
    width: '100%',
  },
  moreInfoWrap: {
    overflowWrap: 'break-word',
  },
  detailsTableHeading: {
    textAlign: 'left',
  },
  dateRange: {
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  dateRangeSelector: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10
  },
  dateRangeValue: {
    fontSize: 15,
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  buttonLink: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderStyle: 'none',
    borderColor: 'transparent',
    borderImage: 'none',
  },
  backLink: {
    color: 'black',
    fontSize: 20,
  },
  chartbox: {
    width: "100%",
  },
  chart: {
    height: 300,
    width: "75%",
  },
  legend: {
    width: "25%",
  },
  viewDot: {
    height: '15px',
    width: '15px',
    backgroundColor: '#01016f',
    borderRadius: '50%',
    display: 'inline-block'
  },
  claimDot: {
    height: '15px',
    width: '15px',
    backgroundColor: '#d8031c',
    borderRadius: '50%',
    display: 'inline-block'
  },
  viewStat: {
    color: '#01bfa5',
    fontWeight: 'bold'
  },
  claimStat: {
    color: '#01bfa5',
    fontWeight: 'bold'
  },
  legendList: {
    listStyle: 'none'
  },
  idAnalyticsLink : {
    color: 'blue',
    textDecoration: 'underline',
    width: '70px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableControls: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  sortControl: {
    marginRight: 20
  }
});

function getDaysInbetweenDatesUTCMap(startDate, endDate) {
  const dayMap = {};
  const dt = new Date(startDate);
  const dtEnd = new Date(endDate);

  do {
    
    // Add to map
    dayMap[dt.toISOString()] = { 
      day: dt.toISOString(),
      event_count: 0,
      // NOTE: Do not use these values based on the current API response, 
      //       you can't calculate uniques when also grouping by offers
      // user_count: 0,
      // referrer_count: 0,
      events: [],
    };
    
    // Increment
    dt.setDate(dt.getDate() + 1)
  }
  while(dt < dtEnd);
  
  return dayMap;
}

function convertLocalDateToUTCIgnoringTimezone(date) {
  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

  return new Date(timestamp);
}

function formatToD3Compatible(dateString) {
  // console.log(dateString);
  const date = new Date(dateString);
  // console.log(date.toUTCString());
  // console.log(`${date.getUTCFullYear()}-${('0' + (date.getUTCMonth()+1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`);
  return `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth()+1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`;
  // return format(date, `yyyy-MM-dd`)
}

// Not currently used as requirements change in offer analytics table
// const orderingFunctions = {
//   date: ({viewCount, claimCount, offerGroupMap, dateGroupMap}) => {
    
//     // NOTE: offerGroupMap is empty as unused for now & dateGroupMap is disabled due to requirements change


//     // console.log(dateGroupMap);

//     const sortedDateArray = Object.keys(dateGroupMap).sort((a, b) => Date.parse(b) - Date.parse(a));
//     if(sortedDateArray.length === 0) {
//       // No Data
//       return [];
//     }

//     // debugger

//     //   return { 
//     //     date: new Date(),
//     //     records: [{ offerId: '', title: '', subtitle: '', views: 0, claims: 0 }] }
//     // })

//     // Descending order
//     const resp = sortedDateArray.map(d => {
//       // Each of d's value is an offer ID
//       const rec = dateGroupMap[d]; // object map of offers by ID
//       // debugger
//       return {
//         date: new Date(d),
//         rows: Object.keys(rec).map(r => {
//           // debugger
//           return {
//             id: r, 
//             claim_count: Number.parseInt((rec[r].claim || {}).event_count || 0),
//             view_count: Number.parseInt((rec[r].view || {}).event_count || 0)
//           }
//         })
//       }
//     })

//     // console.log(resp)
//     return resp;
//   },
// }

const BrandAnalyticsPage = ({ history, match, location }) => {
  const title = 'Brand Analytics';
  
  const classes = useStyles();

  const { brandId } = match.params;
  
  // Protect
  useAuthenticated();

  // Analytics Data
  const [brandAnalytics, setBrandAnalytics] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Brand Details
  const [brandDetails, setBrandDetails] = useState(null);
  const [loadingBrandDetails, setLoadingBrandDetails] = useState(false);
  const [errorBrandDetails, setErrorBrandDetails] = useState(null);

  // Offer Table Data & Sort
  const [sortOrder, setSortOrder] = useState('created');
  const [offers, setOffers] = useState(null);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [errorOffers, setErrorOffers] = useState(null);

  // Date Range Popup
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDateRangeClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleDateRangeClose = () => { setAnchorEl(null); };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // ----

  // Parse URL params
  const params = new URLSearchParams(history.location.search);
  const startDateParam = params.get("startDate") ? params.get("startDate") : null;
  const endDateParam = params.get("endDate") ? params.get("endDate") : null;
  // Only watch the param version as to avoid useEffect looping

  const startDate = startOfDay(startDateParam ? Date.parse(startDateParam) : subDays(new Date(), 7)); // -7 days
  const endDate = endOfDay(endDateParam ? Date.parse(endDateParam) : (new Date())); // now
  
  // console.log('Should show non-utc min/max dates:');
  // console.log('Loading start: ', startDate.toISOString());
  // console.log('Loading end: ', endDate.toISOString());
  // console.log('Should show local min/max dates:');
  // console.log('Loading start: ', startDate.toString());
  // console.log('Loading end: ', endDate.toString());
  
  
  // Chart state
  const [state, setState] = useState([
    {
      startDate,
      endDate,
      key: 'selection'
    }
  ]);
  
  const getBrandAnalytics = () => {

    // HACK: We ignore the local timezone from the Date
    const startAsUTC = convertLocalDateToUTCIgnoringTimezone(startDate);
    const endAsUTC = convertLocalDateToUTCIgnoringTimezone(endDate);
    
    // Testing
    // console.log('Requesting:');
    // console.log(startAsUTC.toISOString());
    // console.log(endAsUTC.toISOString());
    const startUTCString = startAsUTC.toISOString();
    const endUTCString = endAsUTC.toISOString();

    getAnalyticsByBrandId(brandId, { startDate: startUTCString, endDate: endUTCString })
    .then(result => {

      setLoading(false);
      
      if (result) {

        const analyticsData = {
          viewCount: 0,
          claimCount: 0,
          offerGroupMap: {},
          // dateGroupMap: {}
        };

        const viewMap = getDaysInbetweenDatesUTCMap(startUTCString, endUTCString);
        const claimMap = JSON.parse(JSON.stringify(viewMap)); // Quick copy of base structure

        // console.log(JSON.stringify(viewMap));
        // console.log(JSON.stringify(claimMap));

        // Override data to the day placeholders
        result.views.forEach(i => {
          // Chart data
          const oldVal = viewMap[i.day];
          if(oldVal) {
            // Add new values
            // console.log('1', oldVal.events);
            oldVal.events.push(i);
            oldVal.event_count += Number.parseInt(i.event_count);
          } else {
            // First time seeing data for this date
            viewMap[i.day] = { 
              day: i.day,
              event_count: Number.parseInt(i.event_count),
              events: [i],
            }
          }

          // Offer Map Grouping
          // Broken: viewRecord can be seen as undefined as this structure makes incorrect assumptions
          // const offerRef = analyticsData.offerGroupMap[i.offerId];
          // if(offerRef) {
          //   offerRef.view_count += Number.parseInt(i.event_count);
          //   console.log('2', offerRef.viewRecords);
          //   offerRef.viewRecords.push(i);
          // } else {
          //   analyticsData.offerGroupMap[i.offerId] = {
          //     view_count: Number.parseInt(i.event_count),
          //     viewRecords: [i]
          //   }
          // }

          // Offer Map Grouping
          // let dateRef = analyticsData.dateGroupMap[i.day];
          // // Ensure day exists
          // if(!dateRef) {
          //   dateRef = analyticsData.dateGroupMap[i.day] = {};
          // }
          // if(dateRef[i.offerId]) {
          //   if(dateRef[i.offerId].view !== undefined) { console.error(`Duplicate view for ${i.offerId} on ${i.day}`) }
          //   dateRef[i.offerId].view = i;
          // } else {
          //   dateRef[i.offerId] = {
          //     view: i
          //   }
          // }

        });

        result.claims.forEach(i => {
          // Chart data
          const oldVal = claimMap[i.day];
          if(oldVal) {
            // Add new values
            // console.log('3', oldVal.events);
            oldVal.events.push(i);
            oldVal.event_count += Number.parseInt(i.event_count);
          } else {
            // First time seeing data for this date
            claimMap[i.day] = { 
              day: i.day,
              event_count: Number.parseInt(i.event_count),
              events: [i],
            }
          }

          // Offer Map Grouping
          // Broken: claimRecord can be seen as undefined as this structure makes incorrect assumptions
          // const offerRef = analyticsData.offerGroupMap[i.offerId];
          // if(offerRef) {
          //   offerRef.claim_count += Number.parseInt(i.event_count);
          //   console.log('4', offerRef.claimRecords);
          //   offerRef.claimRecords.push(i);
          // } else {
          //   analyticsData.offerGroupMap[i.offerId] = {
          //     claim_count: Number.parseInt(i.event_count),
          //     claimRecords: [i]
          //   }
          // }

          // Offer Map Grouping
          // let dateRef = analyticsData.dateGroupMap[i.day];
          // // Ensure day exists
          // if(!dateRef) {
          //   dateRef = analyticsData.dateGroupMap[i.day] = {};
          // }
          // if(dateRef[i.offerId]) {
          //   if(dateRef[i.offerId].claim !== undefined) { console.error(`Duplicate claim for ${i.offerId} on ${i.day}`) }
          //   dateRef[i.offerId].claim = i;
          // } else {
          //   dateRef[i.offerId] = {
          //     claim: i
          //   }
          // }

        });
        
        const viewDataArray = Object.values(viewMap);
        const claimDataArray = Object.values(claimMap);

        const graphData = [
          {
            id: 'Clicks',
            color: '#d8031c',
            data: []
          },
          {
            id: 'Views',
            color: '#01016f',
            data: []
          }
        ];

        viewDataArray.forEach(i => { 
          const evCount = Number.parseInt(i.event_count);
          
          // Add to total count
          analyticsData.viewCount += evCount;
          
          // Add to views data
          // console.log('5', graphData[1].data);
          graphData[1].data.push({ x: formatToD3Compatible(i.day), y: evCount });
        });


        claimDataArray.forEach(i => { 
          const evCount = Number.parseInt(i.event_count);
          
          // Add to total count
          analyticsData.claimCount += evCount;
          
          // Add to claims data
          // console.log('6', graphData[0].data);
          graphData[0].data.push({ x: formatToD3Compatible(i.day), y: evCount });
        });

        analyticsData.graphData = graphData;
        
        // console.log('3');
        // console.log(analyticsData);
        
        setBrandAnalytics(analyticsData);

      } else {
        setBrandAnalytics({});
      }
    })
    .catch(err => {
      setError(err);
      setLoading(false);
    });
  }

  const getBrandDetails = () => {

    api.get(`/admin/brands/${brandId}`)
      .then(result => {
        setLoadingBrandDetails(false);
        if (result.data) {
          setBrandDetails(result.data);
        } else {
          setBrandDetails(null);
        }
      })
      .catch(err => {
        setErrorBrandDetails(err);
        setLoadingBrandDetails(false);
      });
  }

  const orderFunctions = {
    created: (data) => {
      return data.sort((a, b) => (new Date(b.createdAt)) - (new Date(a.createdAt)));
    },
    views: (data) => {
      return data.sort((a, b) => (Number.parseInt(b.view_count)) - (Number.parseInt(a.view_count)));
    },
    claims: (data) => {
      return data.sort((a, b) => (Number.parseInt(b.claim_count)) - (Number.parseInt(a.claim_count)));
    },
  }

  const getOffers = () => {

    api.get(`/admin/brands/${brandId}/offers/analytics`)
      .then(result => {
        setLoadingOffers(false);
        if (result.data) {
          setOffers(orderFunctions[sortOrder](result.data));
        } else {
          setOffers(null);
        }
      })
      .catch(err => {
        setErrorOffers(err);
        setLoadingOffers(false);
      });
  }

  useEffect(() => {
    
    setLoading(true);
    setLoadingBrandDetails(true);
    setLoadingOffers(true);

    setError(null);
    setErrorBrandDetails(null);
    setErrorOffers(null);

    getBrandAnalytics();
    getBrandDetails();
    getOffers();
  }, [brandId, startDateParam, endDateParam]);

  const changeSortBy = (ev) => {
    setSortOrder(ev.target.value);
    setOffers(orderFunctions[ev.target.value](offers));
  }

  const downloadOfferData = () => {
    jsonExport(offers, (err, csv) => {
      downloadCSV(csv, "offers");
    });
  }

  return (
    <>
      <Title title={title} />
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={11}>
            <Link
              className={classes.backLink}
              to={{ pathname: '/brands' }}
              aria-label="Back">
                <ArrowBackIcon style={{fontSize: '20px'}}/> Back
            </Link>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
                { loadingBrandDetails && <CircularProgress className={classes.loader} />}
                { ((!loadingBrandDetails && errorBrandDetails) || (!loadingBrandDetails && !brandDetails)) && <div>{errorBrandDetails ? errorBrandDetails.message : 'Failed to load data'}</div>}
                { (!loadingBrandDetails && brandDetails) && (
                  <div>
                    <h3>Brand Details</h3>
                    <table className={classes.detailsTable}>
                      <thead className={classes.detailsTableHeading}>
                        <tr>
                          <th className="text-gray">Name</th>
                          <th className="text-gray">Email address</th>
                          <th className="text-gray">Phone number</th>
                          <th className="text-gray">Is featured?</th>
                          <th className="text-gray"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{brandDetails.name || '-'}</td>
                          <td>{brandDetails.email || '-'}</td>
                          <td>{brandDetails.phone || '-'}</td>
                          <td>{brandDetails.isFeatured ? 'Yes' : 'No'}</td>
                          <td><Link to={{ pathname: location.pathname.replace('analytics', 'edit') }}>Edit</Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
            </Paper>
            <div className={`${classes.paper} ${classes.dateRange}`}>
              
                <button aria-describedby={id} onClick={handleDateRangeClick} className={`${classes.dateRangeSelector} ${classes.buttonLink}`}>
                  <DateRangeIcon style={{fontSize: '20px'}}/>
                  <span className={classes.dateRangeValue}>{startDate ? format(
                      startDate,
                      'MMM dd, yyyy'
                    ) : '?'} - {endDate ? format(
                      endDate,
                      'MMM dd, yyyy'
                    ) : '?'}</span> <ArrowDropDownIcon style={{fontSize: '20px'}}/>
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleDateRangeClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <DateRangePicker
                    onChange={item => {
                      
                      setState([item.selection]);

                      if(item.selection.startDate < item.selection.endDate){
                        // Range has been selected
                        
                        // Close popup before render
                        handleDateRangeClose();
                        
                        const dateStart = format(
                          startOfDay(item.selection.startDate),
                          `yyyy-MM-dd'T'HH:mm:ss`
                        );
                        
                        const dateEnd = format(
                          endOfDay(item.selection.endDate),
                          `yyyy-MM-dd'T'HH:mm:ss`
                        );

                        // NOTE: This does not convert the value to UTC, only removes the timezone
                        //       Business decision approved as the simplest method of aligning user timezones
                        
                        // Navigate to new route with range
                        history.push(
                          `${history.location.pathname}?startDate=${dateStart}&endDate=${dateEnd}`
                        );
                      }
                      
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    inputRanges = {[]}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                  />
                </Popover>
            </div>

            <Paper className={classes.paper}>
              <h3>Overview</h3>
                { loading && <CircularProgress className={classes.loader} />}
                { ((!loading && error) || (!loading && (!brandAnalytics || !brandAnalytics.graphData))) && <div>{error ? error.message : 'Failed to load data'}</div>}
                { (!loading && brandAnalytics && brandAnalytics.graphData) && <Grid container className={classes.chartbox}>
                  
                  <Grid item xs={9} className={classes.chart}>
                    <TimeLineChart data={brandAnalytics.graphData} colors={d => d.color} />
                  </Grid>
                  <Grid item xs={3} className={classes.legend}>
                    <ul className={classes.legendList}>
                      <li className="text-gray">
                        <span className={classes.viewDot} /> Offer Views: <span className={classes.viewStat}>{brandAnalytics.viewCount}</span>
                      </li>
                      <li className="text-gray">
                        <span className={classes.claimDot} /> Offer Claims: <span className={classes.claimStat}>{brandAnalytics.claimCount}</span>
                      </li>
                    </ul>
                  </Grid>
                </Grid> }
                  
            </Paper>

          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>
              <h3>More Details</h3>
                { loadingBrandDetails && <CircularProgress className={classes.loader} />}
                { ((!loadingBrandDetails && errorBrandDetails) || (!loadingBrandDetails && !brandDetails)) && <div>{errorBrandDetails ? errorBrandDetails.message : 'Failed to load data'}</div>}
                { (!loadingBrandDetails && brandDetails) && (
                  <div className={classes.moreInfoWrap}>
                    <h4 className="text-gray">Users</h4>
                    <span>{brandDetails.users ? brandDetails.users.join(', ') : 'No Users'}</span>
                    <h4 className="text-gray">Description</h4>
                    <span>{brandDetails.description || '-'}</span>
                    <h4 className="text-gray">Tagline</h4>
                    <span>{brandDetails.tagline || '-'}</span>
                    <h4 className="text-gray">Logo</h4>
                    <span>{brandDetails.logoUrl ? <img src={brandDetails.logoUrl} alt="brand" className={classes.image} /> : 'No Image'}</span>
                  </div> 
                )}
              
            </Paper>
          </Grid>
          <Grid item xs={11}>
            <Paper className={classes.paper}>
                <div>
                  <Grid container>
                    <Grid item xs={6}>
                      <h3>Offers</h3>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.tableControls}>
                      <Button color="primary" variant="outlined" aria-label="Offer Export" onClick={downloadOfferData}><GetAppIcon style={{fontSize: '18px'}}/> Export CSV</Button>
                      <FormControl className={classes.sortControl}>
                        <Select
                          value={sortOrder}
                          onChange={changeSortBy}
                          inputProps={{
                            name: 'sort',
                            id: 'table-sort',
                          }}
                        >
                          <MenuItem value={'created'}>Sort Offers By Date</MenuItem>
                          <MenuItem value={'claims'}>Sort Offers By Clicks</MenuItem>
                          <MenuItem value={'views'}>Sort Offers By Views</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                { loadingOffers && <CircularProgress className={classes.loader} />}
                { ((!loadingOffers && errorOffers) || (!loadingOffers && !offers)) && <div>{errorOffers ? errorOffers.message : 'Failed to load offers'}</div>}
                { (!loadingOffers && offers) &&
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Created</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Subtitle</TableCell>
                      <TableCell>Views</TableCell>
                      <TableCell>Clicks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offers.map(offer => {
                      return (
                        <TableRow key={offer.id}>
                          <TableCell>{format(Date.parse(offer.createdAt), 'dd.MM.yy')}</TableCell>
                          <TableCell>
                            <Link
                              className={classes.idAnalyticsLink}
                              to={{ pathname: `/offers/${offer.id}/analytics` }}
                              aria-label={`Offer link to ${offer.id}`}>
                              {offer.id}
                            </Link>
                          </TableCell>
                          <TableCell>{offer.title}</TableCell>
                          <TableCell>{offer.subtitle}</TableCell>
                          <TableCell>{offer.view_count}</TableCell>
                          <TableCell>{offer.claim_count}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withRouter(BrandAnalyticsPage);