// in src/MyAppBar.js
import React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";

const useStyles = makeStyles({
  spacer: {
    flex: 1
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
});

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography className={classes.title} id="react-admin-title" />
      <Logo />
      <span className={classes.spacer} />
    </AppBar>
  )
  };

export default MyAppBar;
