import firebase from "../firebase";
import getFirebaseUser from "./getFirebaseUserInfo";

export default function(user) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await getFirebaseUser(user.id);
      if (
        data.providerData &&
        data.providerData.some(provider => provider.providerId === "password")
      ) {
        await firebase.auth().sendPasswordResetEmail(user.email);
        resolve("Email sent!");
      } else {
        throw new Error("This user auth type is not password based");
      }
    } catch (e) {
      reject(e);
    }
  });
}
