import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  SimpleShowLayout,
  Show,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageField,
  ImageInput,
  BooleanField,
  BooleanInput,
} from "react-admin";

import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import TimelineIcon from "@material-ui/icons/Timeline";

import StyleInput from "../inputs/StyleInput";

import formatImageInput from '../formatters/imageFormatter';

const AnalyticsButton = (props) => (
  <IconButton component={Link} to={
      { pathname: `${props.basePath}/${props.record.id}/analytics` }
  } color="primary" aria-label="Analytics"  ><TimelineIcon style={{fontSize: '20px'}}/></IconButton>
);

const BrandsReferenceShow = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <TextField source="style" />
        <TextField source="tagline" />
        <TextField source="description" />
        <ImageField source="logoUrl" />
        <ImageField source="backgroundPhotoUrl" />
        <ReferenceArrayField label="Users" reference="users" source="users">
          <SingleFieldList>
            <ChipField source="email" />
          </SingleFieldList>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export const BrandsList = props => (
  <List {...props}>
    <Datagrid expand={<BrandsReferenceShow />}>
      <AnalyticsButton />
      <TextField source="name" />
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="phone" />
      <BooleanField source="isFeatured" />
      <EditButton />
    </Datagrid>
  </List>
);
export const BrandsEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput multiline source="description" />
      <TextInput multiline source="tagline" />
      <StyleInput source="style" />
      <ImageInput
        source="logoUrl"
        label="Logo Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="backgroundPhotoUrl"
        label="Background Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceArrayInput
        source="users"
        reference="users"
        label="Users"
        allowEmpty
      >
        <SelectArrayInput optionText="email" />
      </ReferenceArrayInput>
      <BooleanInput label="Is Featured?" source="isFeatured" />
    </SimpleForm>
  </Edit>
);
export const BrandsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput multiline source="description" />
      <TextInput multiline source="tagline" />
      <StyleInput source="style" />
      <ImageInput
        source="logoUrl"
        label="Logo Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="backgroundPhotoUrl"
        label="Background Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceArrayInput
        source="users"
        reference="users"
        label="Users"
        allowEmpty
      >
        <SelectArrayInput optionText="email" />
      </ReferenceArrayInput>
      <BooleanInput label="Is Featured?" source="isFeatured" />
    </SimpleForm>
  </Create>
);
export const BrandsShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="style" />
      <TextField source="tagline" />
      <TextField source="description" />
      <ImageField source="logoUrl" />
      <ImageField source="backgroundPhotoUrl" />
      <ReferenceArrayField label="Users" reference="users" source="users">
        <SingleFieldList>
          <ChipField source="email" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
    <BooleanField source="isFeatured" />
  </Show>
);
