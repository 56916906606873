import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title, useAuthenticated } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import getFeedSourcesAsync from "../common/getFeedSources";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  }
});

const FeedPage = () => {
  
  const classes = useStyles();

  // Protect
  useAuthenticated();

  const [feedSources, setFeedSources] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getFeedSources = () =>
    getFeedSourcesAsync()
      .then(result => {
        setLoading(false);
        if (result.data) {
          setFeedSources(result.data);
        } else {
          setFeedSources([]);
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  useEffect(() => {
    setLoading(true);
    setError(null);
    getFeedSources();
  }, []);
  if (loading || !feedSources)
    return (
      <Card>
        <Title title="Feeds" />
        <CardContent>Loading</CardContent>
      </Card>
    );
  if (error)
    return (
      <Card>
        <Title title="Feeds" />
        <CardContent>{error.message}</CardContent>
      </Card>
    );

  return (
    <Card>
      <Title title="Feeds" />
      <CardContent>
        <Paper className={classes.root}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Url</TableCell>
                <TableCell align="right">Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feedSources.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={row.url}
                    >
                      {row.url}
                    </a>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <Button
                      color="primary"
                      component={Link}
                      to={`/feeds/${row.id}`}
                    >
                      Create using feed
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default FeedPage;
