import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  SimpleShowLayout,
  Show,
  RichTextField,
  ImageField,
  DateTimeInput,
  SelectInput,
  ReferenceInput,
  ImageInput,
  BooleanInput,
  BooleanField,
  FormDataConsumer,
  downloadCSV
} from "react-admin";
import { Link } from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton";
import TimelineIcon from "@material-ui/icons/Timeline";
import RichTextInput from "ra-input-rich-text";
import jsonExport from "jsonexport";
import TagsField from "../fields/TagsField";
import TagsInput from "../inputs/TagsInput";

import formatImageInput from '../formatters/imageFormatter';

const AnalyticsButton = (props) => (
  <IconButton component={Link} to={
      { pathname: `${props.basePath}/${props.record.id}/analytics` }
  } color="primary" aria-label="Analytics"  ><TimelineIcon style={{fontSize: '20px'}}/></IconButton>
);

const exporter = (records, fetchRelatedRecords) => {
  // will call dataProvider(GET_MANY, 'posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
  fetchRelatedRecords(records, "publisherId", "brands").then(brands => {
    const data = records.map(record => {
      const result = {
        ...record
      };
      const b = brands[record.publisherId];
      if (b) {
        result.author = b.name;
      }
      return result;
    });
    jsonExport(data, (err, csv) => {
      downloadCSV(csv, "offers");
    });
  });
};

const OffersReferenceShow = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <ImageField source="photoUrl" title="Photo" />
        <ImageField source="productLogoUrl" title="Product Logo" />
        <RichTextField source="description" />
      </SimpleShowLayout>
    </Show>
  );
};

export const OffersList = props => (
  <List {...props} exporter={exporter}>
    <Datagrid expand={<OffersReferenceShow />}>
      <AnalyticsButton />
      <ReferenceField
        label="Brand Name"
        source="publisherId"
        reference="brands"
        sortBy="brandName"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="status" />
      <TextField source="code" />
      <TextField source="subtitle" />
      <TextField source="externalLink" />
      <EditButton />
    </Datagrid>
  </List>
);
export const OffersEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="title" />
      <TagsInput />

      <RichTextInput source="description" />
      <SelectInput
        source="status"
        choices={[
          { id: "active", name: "active" },
          { id: "draft", name: "draft" }
        ]}
      />
      <SelectInput
        source="redeemType"
        choices={[
          { id: "web", name: "web" },
          { id: "location", name: "location" }
        ]}
      />
      <TextInput source="code" />
      <TextInput source="subtitle" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.redeemType === "web" && (
            <TextInput source="externalLink" {...rest} />
          )
        }
      </FormDataConsumer>
      <ReferenceInput label="Publisher" source="publisherId" reference="brands">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="categories"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput label="Is Premium?" source="isPremium" />
      <BooleanInput label="Is Sponsored?" source="isSponsored" />
      <BooleanInput label="Is Front?" source="isFront" />
      <ImageInput
        source="photoUrl"
        label="Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="productLogoUrl"
        label="Product Logo"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const OffersCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TagsInput />
      <RichTextInput source="description" />
      <SelectInput
        source="status"
        choices={[
          { id: "active", name: "active" },
          { id: "draft", name: "draft" }
        ]}
      />
      <SelectInput
        source="redeemType"
        choices={[
          { id: "web", name: "web" },
          { id: "location", name: "location" }
        ]}
      />
      <TextInput source="code" />
      <TextInput source="subtitle" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.redeemType === "web" && (
            <TextInput source="externalLink" {...rest} />
          )
        }
      </FormDataConsumer>

      <ReferenceInput label="Publisher" source="publisherId" reference="brands">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="categories"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput label="Is Premium?" source="isPremium" />
      <BooleanInput label="Is Sponsored?" source="isSponsored" />
      <BooleanInput label="Is Front?" source="isFront" />
      <ImageInput
        source="photoUrl"
        label="Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="productLogoUrl"
        label="Product Logo"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
export const OffersShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="status" />
      <TextField source="redeemType" />
      <TextField source="code" />
      <TextField source="subtitle" />
      <TextField source="startDate" />
      <TextField source="endDate" />
      <TagsField source="tags" />
      <ImageField source="photoUrl" title="Photo" />
      <ImageField source="productLogoUrl" title="Product Logo" />
      <RichTextField source="description" />
      <TextField source="externalLink" />
      <BooleanField source="isPremium" />
      <BooleanField source="isSponsored" />
      <BooleanField source="isFront" />
      <TextField source="claimedCount" />
      <ReferenceField
        label="Author"
        source="publisherId"
        reference="brands">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Category"
        source="categoryId"
        reference="categories"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
