// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title, useAuthenticated } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

import Paper from "@material-ui/core/Paper";
import getAuditEventsAsync from "../common/getAuditEventsByUserId";

const useStyles = makeStyles({
  root: {
    marginTop: 100,
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  }
});

const AuditEventsPage = ({ history, match }) => {
  const { userId } = match.params;
  const classes = useStyles();

  // Protect
  useAuthenticated();
  
  const [auditEvents, setAuditEvents] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = new URLSearchParams(history.location.search);
  const page = params.get("page") ? Number.parseInt(params.get("page")) : 1;
  const perPage = params.get("perPage")
    ? Number.parseInt(params.get("perPage"))
    : 10;
  const getAuditEvents = () =>
    getAuditEventsAsync(userId, page, perPage)
      .then(result => {
        setLoading(false);
        if (result.data) {
          const { results, total } = result.data;
          if (results && total) {
            setTotal(total);
            setAuditEvents(results);
          }
        } else {
          setAuditEvents([]);
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  useEffect(() => {
    setLoading(true);
    setError(null);
    getAuditEvents();
  }, [page, perPage]);

  if (loading || !auditEvents)
    return (
      <Card>
        <Title title="Audit Events" />
        <CardContent>Loading</CardContent>
      </Card>
    );
  if (error)
    return (
      <Card>
        <Title title="Audit Events" />
        <CardContent>{error.message}</CardContent>
      </Card>
    );
  return (
    <Card>
      <Title title="Audit Events" />
      <CardContent>
        <Paper className={classes.root}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Message</TableCell>
                <TableCell align="right">Level</TableCell>
                <TableCell align="right">Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditEvents.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">{row.message}</TableCell>
                  <TableCell align="right">{row.level}</TableCell>
                  <TableCell align="right">{row.timestamp}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page - 1}
                  rowsPerPage={perPage}
                  count={total}
                  onChangeRowsPerPage={event => {
                    if (event.target.value * (page - 1) < total)
                      history.push(
                        `${history.location.pathname}?page=${page}&perPage=${
                          event.target.value
                        }`
                      );
                    else if (event.target.value > total) {
                      history.push(
                        `${history.location.pathname}?page=${1}&perPage=${
                          event.target.value
                        }`
                      );
                    } else {
                      const newPage = Math.ceil(total / event.target.value);
                      history.push(
                        `${history.location.pathname}?page=${newPage}&perPage=${
                          event.target.value
                        }`
                      );
                    }
                  }}
                  onChangePage={(event, p) => {
                    history.push(
                      `${history.location.pathname}?page=${p +
                        1}&perPage=${perPage}`
                    );
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default withRouter(AuditEventsPage);
