import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";

// Menu Icons
import BusinessIcon from "@material-ui/icons/Business";
import CategoryIcon from "@material-ui/icons/Category";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PeopleIcon from "@material-ui/icons/People";
import DescriptionIcon from "@material-ui/icons/Description";
// import CategoryIcon from "@material-ui/icons/Category"; // TODO: ask Jack what he prefers
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import RssFeedIcon from "@material-ui/icons/RssFeed";

import CssBaseline from '@material-ui/core/CssBaseline';

import authProvider from "./authProvider";
import {
  BrandsCreate,
  BrandsEdit,
  BrandsList,
  BrandsShow
} from "./pages/brands";
import {
  ServicesCreate,
  ServicesEdit,
  ServicesList,
  ServicesShow
} from "./pages/services";
import {
  OffersCreate,
  OffersEdit,
  OffersList,
  OffersShow
} from "./pages/offers";
import { UsersEdit, UsersShow, UsersList, UsersCreate } from "./pages/users";
import {
  AdvertsCreate,
  AdvertsEdit,
  AdvertsList,
  AdvertsShow
} from "./pages/adverts";
import {
  ContentCreate,
  ContentEdit,
  ContentList,
  ContentShow
} from "./pages/content";
import {
  CategoriesCreate,
  CategoriesEdit,
  CategoriesList,
  CategoriesShow
} from "./pages/categories";
import {
  FeedSourcesCreate,
  FeedSourcesEdit,
  FeedSourcesList,
  FeedSourcesShow
} from "./pages/feedSources";
import theme from "./themes/themeBasic";
import Layout from "./layout/Layout";
import LoginPage from "./layout/LoginPage";
import Dashboard from "./layout/Dashboard";
import FeedSourcesPage from "./pages/feeds";
import AuditEventsPage from "./pages/auditEvents";
import FeedSourcePage from "./pages/feed";
import OfferAnalyticsPage from "./pages/offerAnalytics";
import BrandAnalyticsPage from "./pages/brandAnalytics";

import dataProvider from "./dataProvider";

class App extends Component {
  render() {
    return (
      <>
        <CssBaseline />
        <Admin
          customRoutes={[
            <Route exact path="/feeds" component={FeedSourcesPage} />,
            <Route
              exact
              path="/feeds/:feedSourceId"
              component={FeedSourcePage}
            />,
            <Route
              exact
              path="/users/:userId/audit-events"
              component={AuditEventsPage}
            />,
            <Route
              exact
              path="/offers/:offerId/analytics"
              component={OfferAnalyticsPage}
            />,
            <Route
              exact
              path="/brands/:brandId/analytics"
              component={BrandAnalyticsPage}
            />
          ]}
          dashboard={Dashboard}
          loginPage={LoginPage}
          layout={Layout}
          theme={theme}
          authProvider={authProvider}
          dataProvider={dataProvider}
        >
          {permissions => {
            // Only include the categories resource for admin users
            return permissions === "admin"
              ? [
                  <Resource
                    name="brands"
                    list={BrandsList}
                    edit={BrandsEdit}
                    create={BrandsCreate}
                    show={BrandsShow}
                    icon={BusinessIcon}
                  />,
                  <Resource
                    name="services"
                    list={ServicesList}
                    edit={ServicesEdit}
                    create={ServicesCreate}
                    show={ServicesShow}
                    icon={CategoryIcon}
                  />,
                  <Resource
                    name="offers"
                    list={OffersList}
                    edit={OffersEdit}
                    create={OffersCreate}
                    show={OffersShow}
                    icon={LocalOfferIcon}
                  />,
                  <Resource
                    name="users"
                    list={UsersList}
                    edit={UsersEdit}
                    show={UsersShow}
                    create={UsersCreate}
                    icon={PeopleIcon}
                  />,
                  <Resource
                    name="content"
                    list={ContentList}
                    edit={ContentEdit}
                    create={ContentCreate}
                    show={ContentShow}
                    icon={DescriptionIcon}
                  />,
                  <Resource
                    name="categories"
                    list={CategoriesList}
                    edit={CategoriesEdit}
                    create={CategoriesCreate}
                    show={CategoriesShow}
                    // icon={CategoryIcon}
                  />,
                  <Resource
                    name="adverts"
                    list={AdvertsList}
                    edit={AdvertsEdit}
                    create={AdvertsCreate}
                    show={AdvertsShow}
                    icon={MonetizationOnIcon}
                  />,
                  <Resource
                    name="feedSources"
                    options={{ label: "Feed Sources" }}
                    list={FeedSourcesList}
                    edit={FeedSourcesEdit}
                    create={FeedSourcesCreate}
                    show={FeedSourcesShow}
                    icon={RssFeedIcon}
                  />
                ]
              : [];
          }}
        </Admin>
      </>
    );
  }
}

export default App;
