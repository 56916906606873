// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import { Title, useAuthenticated } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Paper from "@material-ui/core/Paper";
import Popover from '@material-ui/core/Popover';

import { DateRangePicker } from 'react-date-range';
import { subDays, endOfDay, startOfDay, format } from 'date-fns';

import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import api from "../api";

import TimeLineChart from '../layout/TimeLineChart';

import getAnalyticsByOfferIdAsync from "../common/getAnalyticsByOfferId";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: 10,
  },
  paper: {
    padding: 15,
  },
  loader: {
    display: 'block',
    margin: 'auto',
    padding: 20
  },
  detailsTable: {
    width: '100%',
    lineHeight: '2em',
  },
  idCell: {
    width: '70px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  image: {
    width: '100%',
  },
  moreInfoWrap: {
    overflowWrap: 'break-word',
  },
  detailsTableHeading: {
    textAlign: 'left',
  },
  dateRange: {
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  dateRangeSelector: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10
  },
  dateRangeValue: {
    fontSize: 15,
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  buttonLink: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderStyle: 'none',
    borderColor: 'transparent',
    borderImage: 'none',
  },
  backLink: {
    color: 'black',
    fontSize: 20,
  },
  chartbox: {
    width: "100%",
  },
  chart: {
    height: 300,
    width: "75%",
  },
  legend: {
    width: "25%",
  },
  viewDot: {
    height: '15px',
    width: '15px',
    backgroundColor: '#01016f',
    borderRadius: '50%',
    display: 'inline-block'
  },
  claimDot: {
    height: '15px',
    width: '15px',
    backgroundColor: '#d8031c',
    borderRadius: '50%',
    display: 'inline-block'
  },
  viewStat: {
    color: '#01bfa6',
    fontWeight: 'bold'
  },
  claimStat: {
    color: '#01bfa6',
    fontWeight: 'bold'
  },
  legendList: {
    listStyle: 'none'
  }
});

function getDaysInbetweenDatesUTCMap(startDate, endDate) {
  const dayMap = {};
  const dt = new Date(startDate);
  const dtEnd = new Date(endDate);

  do {
    // Add to map
    dayMap[dt.toISOString()] = { day: dt.toISOString(), "event_count": 0, "user_count": 0, "referrer_count": 0 };
    
    // Increment
    dt.setDate(dt.getDate() + 1)
  }
  while(dt < dtEnd);
  
  return dayMap;
}

function convertLocalDateToUTCIgnoringTimezone(date) {
  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

  return new Date(timestamp);
}

function formatToD3Compatible(dateString) {
  // console.log(dateString);
  const date = new Date(dateString);
  // console.log(date.toUTCString());
  // console.log(`${date.getUTCFullYear()}-${('0' + (date.getUTCMonth()+1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`);
  return `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth()+1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`;
  // return format(date, `yyyy-MM-dd`)
}

const OfferAnalyticsPage = ({ history, match, location }) => {
  const title = 'Offer Analytics';
  const { offerId } = match.params;
  
  const classes = useStyles();

  // Protect
  useAuthenticated();

  // Analytics Data
  const [offerAnalytics, setOfferAnalytics] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Offer Details
  const [offerDetails, setOfferDetails] = useState(null);
  const [loadingOfferDetails, setLoadingOfferDetails] = useState(false);
  const [errorOfferDetails, setErrorOfferDetails] = useState(null);

  // Date Range Popup
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDateRangeClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleDateRangeClose = () => { setAnchorEl(null); };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // ----

  // Parse URL params
  const params = new URLSearchParams(history.location.search);
  const startDateParam = params.get("startDate") ? params.get("startDate") : null;
  const endDateParam = params.get("endDate") ? params.get("endDate") : null;
  // Only watch the param version as to avoid useEffect looping

  const startDate = startOfDay(startDateParam ? Date.parse(startDateParam) : subDays(new Date(), 7)); // -7 days
  const endDate = endOfDay(endDateParam ? Date.parse(endDateParam) : (new Date())); // now
  
  // console.log('Should show non-utc min/max dates:');
  // console.log('Loading start: ', startDate.toISOString());
  // console.log('Loading end: ', endDate.toISOString());
  // console.log('Should show local min/max dates:');
  // console.log('Loading start: ', startDate.toString());
  // console.log('Loading end: ', endDate.toString());
  
  
  // Chart state
  const [state, setState] = useState([
    {
      startDate,
      endDate,
      key: 'selection'
    }
  ]);
  
  const getOfferAnalytics = () => {

    // HACK: We ignore the local timezone from the Date
    const startAsUTC = convertLocalDateToUTCIgnoringTimezone(startDate);
    const endAsUTC = convertLocalDateToUTCIgnoringTimezone(endDate);
    
    // Testing
    // console.log('Requesting:');
    // console.log(startAsUTC.toISOString());
    // console.log(endAsUTC.toISOString());
    const startUTCString = startAsUTC.toISOString();
    const endUTCString = endAsUTC.toISOString();

    getAnalyticsByOfferIdAsync(offerId, { startDate: startUTCString, endDate: endUTCString })
    .then(result => {

      setLoading(false);
      
      if (result) {

        const viewMap = getDaysInbetweenDatesUTCMap(startUTCString, endUTCString);
        const claimMap = JSON.parse(JSON.stringify(viewMap)); // Quick copy of base structure

        // Override data to the day placeholders
        result.views.forEach(i => {
          viewMap[i.day] = i;
        });
        result.claims.forEach(i => {
          claimMap[i.day] = i;
        });
        
        const viewDataArray = Object.values(viewMap);
        const claimDataArray = Object.values(claimMap);
        
        const analyticsData = {
          viewCount: 0,
          claimCount: 0,
        };

        const graphData = [
          {
            id: 'Claims',
            color: '#d8031c',
            data: []
          },
          {
            id: 'Views',
            color: '#01016f',
            data: []
          }
        ];

        viewDataArray.forEach(i => { 
          const evCount = Number.parseInt(i.event_count);
          
          // Add to total count
          analyticsData.viewCount += evCount;
          
          // Add to views data
          graphData[1].data.push({ x: formatToD3Compatible(i.day), y: Number.parseInt(i.event_count) });
        });


        claimDataArray.forEach(i => { 
          const evCount = Number.parseInt(i.event_count);
          
          // Add to total count
          analyticsData.claimCount += evCount;
          
          // Add to claims data
          graphData[0].data.push({ x: formatToD3Compatible(i.day), y: Number.parseInt(i.event_count) });
        });

        analyticsData.graphData = graphData;

        setOfferAnalytics(analyticsData);

      } else {
        setOfferAnalytics({});
      }
    })
    .catch(err => {
      setError(err);
      setLoading(false);
    });
  }

  const getOfferDetails = () => {

    api.get(`/admin/offers/${offerId}`)
      .then(result => {
        setLoadingOfferDetails(false);
        if (result.data) {
          setOfferDetails(result.data);
        } else {
          setOfferDetails(null);
        }
      })
      .catch(err => {
        setErrorOfferDetails(err);
        setLoadingOfferDetails(false);
      });
  }

  useEffect(() => {
    
    setLoading(true);
    setLoadingOfferDetails(true);

    setError(null);
    setErrorOfferDetails(null);

    getOfferAnalytics();
    getOfferDetails();
  }, [offerId, startDateParam, endDateParam]);

  return (
    <>
      <Title title={title} />
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={11}>
            <Link
              className={classes.backLink}
              to={{ pathname: '/offers' }}
              aria-label="Back">
                <ArrowBackIcon style={{fontSize: '20px'}}/> Back
            </Link>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
                { loadingOfferDetails && <CircularProgress className={classes.loader} />}
                { ((!loadingOfferDetails && errorOfferDetails) || (!loadingOfferDetails && !offerDetails)) && <div>{errorOfferDetails ? errorOfferDetails.message : 'Failed to load data'}</div>}
                { (!loadingOfferDetails && offerDetails) && (
                  <div>
                    <h3>Offer Details</h3>
                    <table className={classes.detailsTable}>
                      <thead className={classes.detailsTableHeading}>
                        <tr>
                          <th className="text-gray">ID</th>
                          <th className="text-gray">Title</th>
                          <th className="text-gray">Subtitle</th>
                          <th className="text-gray">Code</th>
                          <th className="text-gray">Category</th>
                          <th className="text-gray"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className={classes.idCell}>{offerDetails.id || '-'}</td>
                          <td>{offerDetails.title || '-'}</td>
                          <td>{offerDetails.subtitle || '-'}</td>
                          <td>{offerDetails.code || '-'}</td>
                          <td>{offerDetails.category || '-'}</td>
                          <td><Link to={{ pathname: location.pathname.replace('analytics', 'edit') }}>Edit</Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
            </Paper>
            <div className={`${classes.paper} ${classes.dateRange}`}>
              
                <button aria-describedby={id} onClick={handleDateRangeClick} className={`${classes.dateRangeSelector} ${classes.buttonLink}`}>
                  <DateRangeIcon style={{fontSize: '20px'}}/>
                  <span className={classes.dateRangeValue}>{startDate ? format(
                      startDate,
                      'MMM dd, yyyy'
                    ) : '?'} - {endDate ? format(
                      endDate,
                      'MMM dd, yyyy'
                    ) : '?'}</span> <ArrowDropDownIcon style={{fontSize: '20px'}}/>
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleDateRangeClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <DateRangePicker
                    onChange={item => {
                      
                      setState([item.selection]);

                      if(item.selection.startDate < item.selection.endDate){
                        // Range has been selected
                        
                        // Close popup before render
                        handleDateRangeClose();
                        
                        const dateStart = format(
                          startOfDay(item.selection.startDate),
                          `yyyy-MM-dd'T'HH:mm:ss`
                        );
                        
                        const dateEnd = format(
                          endOfDay(item.selection.endDate),
                          `yyyy-MM-dd'T'HH:mm:ss`
                        );

                        // console.log(dateStart);
                        // console.log(dateEnd);

                        // NOTE: This does not convert the value to UTC, only removes the timezone
                        //       Business decision approved as the simplest method of aligning user timezones
                        // const UTCStartDate = convertLocalDateToUTCIgnoringTimezone(item.selection.startDate);
                        // const startDateFloor = startOfDay(UTCStartDate);
                        // console.log(item.selection.startDate.toISOString(), startDateFloor.toISOString(), UTCStartDate.toISOString());

                        // const UTCEndDate = convertLocalDateToUTCIgnoringTimezone(item.selection.endDate);
                        // const endDateMax = endOfDay(UTCEndDate);
                        // console.log(item.selection.endDate.toISOString(), endDateMax.toISOString(), UTCEndDate.toISOString());

                        // TODO: Change when allowing hourly segments
                        // Navigate to new route with range
                        history.push(
                          `${history.location.pathname}?startDate=${dateStart}&endDate=${dateEnd}`
                        );
                      }
                      
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    inputRanges = {[]}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                  />
                </Popover>
            </div>

            <Paper className={classes.paper}>
              <h3>Overview</h3>
                { loading && <CircularProgress className={classes.loader} />}
                { ((!loading && error) || (!loading && (!offerAnalytics || !offerAnalytics.graphData))) && <div>{error ? error.message : 'Failed to load data'}</div>}
                { (!loading && offerAnalytics && offerAnalytics.graphData) && <Grid container className={classes.chartbox}>
                  
                  <Grid item xs={9} className={classes.chart}>
                    <TimeLineChart data={offerAnalytics.graphData} colors={d => d.color} />
                  </Grid>
                  <Grid item xs={3} className={classes.legend}>
                    <ul className={classes.legendList}>
                      <li className="text-gray">
                        <span className={classes.viewDot} /> Offer Views: <span className={classes.viewStat}>{offerAnalytics.viewCount}</span>
                      </li>
                      <li className="text-gray">
                        <span className={classes.claimDot} /> Offer Clicks: <span className={classes.claimStat}>{offerAnalytics.claimCount}</span>
                      </li>
                    </ul>
                  </Grid>
                </Grid> }
                  
            </Paper>

          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>
              <h3>More Details</h3>
                { loadingOfferDetails && <CircularProgress className={classes.loader} />}
                { ((!loadingOfferDetails && errorOfferDetails) || (!loadingOfferDetails && !offerDetails)) && <div>{errorOfferDetails ? errorOfferDetails.message : 'Failed to load data'}</div>}
                { (!loadingOfferDetails && offerDetails) && (
                  <div className={classes.moreInfoWrap}>
                    <h4 className="text-gray">Tags</h4>
                    <span>{offerDetails.tags ? offerDetails.tags.join(', ') : 'No Tags'}</span>
                    <h4 className="text-gray">External Link</h4>
                    <span>{offerDetails.externalLink || '-'}</span>
                    <h4 className="text-gray">Brand</h4>
                    <span>{offerDetails.brand.name || '-'}</span>
                    <h4 className="text-gray">Photo</h4>
                    <span>{offerDetails.photoUrl ? <img src={offerDetails.photoUrl} alt="offer" className={classes.image} /> : 'No Image'}</span>
                  </div> 
                )}
              
            </Paper>
          </Grid>
        </Grid>
      </div>

      
      
      
      {/* <Card>
        <CardContent>
        
          <Paper className={classes.chartbox}>
            {JSON.stringify(offerAnalytics)}
          </Paper>
            
            
            {/* <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id</StyledTableCell>
                  <StyledTableCell align="right">Message</StyledTableCell>
                  <StyledTableCell align="right">Level</StyledTableCell>
                  <StyledTableCell align="right">Timestamp</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditEvents.map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.message}</TableCell>
                    <TableCell align="right">{row.level}</TableCell>
                    <TableCell align="right">{row.timestamp}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TablePagination
                page={page - 1}
                rowsPerPage={perPage}
                count={total}
                onChangeRowsPerPage={event => {
                  if (event.target.value * (page - 1) < total)
                    history.push(
                      `${history.location.pathname}?page=${page}&perPage=${
                        event.target.value
                      }`
                    );
                  else if (event.target.value > total) {
                    history.push(
                      `${history.location.pathname}?page=${1}&perPage=${
                        event.target.value
                      }`
                    );
                  } else {
                    const newPage = Math.ceil(total / event.target.value);
                    history.push(
                      `${history.location.pathname}?page=${newPage}&perPage=${
                        event.target.value
                      }`
                    );
                  }
                }}
                onChangePage={(event, p) => {
                  history.push(
                    `${history.location.pathname}?page=${p +
                      1}&perPage=${perPage}`
                  );
                }}
              />
            </Table>
          
        </CardContent>
      </Card> */}
    </>
  );
};

export default withRouter(OfferAnalyticsPage);