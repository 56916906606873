// in LatLongInput.js
import React from "react";
import { Field } from "react-final-form";
import renderTextInput from "./renderTextInput";

const StyleInput = () => (
  <span>
    <Field
      name="style.primaryColor"
      component={renderTextInput}
      label="Primary"
    />
    &nbsp;
    <Field
      name="style.secondaryColor"
      component={renderTextInput}
      label="Secondary"
    />
  </span>
);
export default StyleInput;
