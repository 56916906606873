import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import './styles.css';

// analytics pages
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
