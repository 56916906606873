// in ./ResetViewsButton.js
import React from "react";
import { connect } from "react-redux";
import { Button,  showNotification } from "react-admin";

const ComplexButton = ({ label, record, onClick, showNotification }) => {
  const handleClick = async () => {
    try {
      const result = await onClick(record);
      showNotification(result, "success");
    } catch (e) {
      showNotification(e.message, "warning");
    }
  };
  return <Button label={label} onClick={handleClick} />;
};

export default connect(
  null,
  {
    showNotification
  }
)(ComplexButton);
