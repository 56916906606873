import React, {
  useRef,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Card,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core';

import { useLogin, useNotify, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';

import DefaultLoginForm from './LoginForm';

const useStyles = makeStyles(
  (theme) => ({
      main: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          height: '1px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '#9BCFC6',
      },
      card: {
          minWidth: 300,
          marginTop: '6em',
      },
      avatar: {
          margin: '1em',
          display: 'flex',
          justifyContent: 'center',
      },
  }),
  { name: 'RaLogin' }
);

const Login = props => {
  const {
      theme,
      classes: classesOverride,
      className,
      children,
      staticContext,
      ...rest
  } = props;

  const containerRef = useRef();
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  
  const login = useLogin();
  const notify = useNotify();

  const checkAuth = useCheckAuth();
  const history = useHistory();
  useEffect(() => {
      checkAuth({}, false)
          .then(() => {
              // already authenticated, redirect to the home page
              history.push('/');
          })
          .catch(() => {
              // not authenticated, stay on the login page
          });
  }, [checkAuth, history]);

  return (
      <ThemeProvider theme={muiTheme}>
          <div
              className={classnames(classes.main, className)}
              {...rest}
              ref={containerRef}
          >
              {/* <Card className={classes.card}> */}
                  <div className={classes.avatar}>
                    <img src="logo.png" alt="logo" style={{
                      height: 40
                    }} />
                  </div>
                  {children}
              {/* </Card> */}
              <Notification />
          </div>
      </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

Login.defaultProps = {
  // theme: defaultTheme,
  children: <DefaultLoginForm />,
};

export default Login;