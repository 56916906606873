import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#5df2d6",
      main: "#00bfa5",
      dark: "#008e76",
      contrastText: "#000000"
    },
    error: {
      light: "#ff634a",
      main: "#ff1e1e",
      dark: "#c30000",
      contrastText: "#000000"
    }
  },
  overrides: {
    MuiToolbar: {
      root: {
        background: "#ffffff",
        color: "#000000"
      },
      regular: {
        background: "#ffffff"
      }
    },
    MuiTableCell: {
      head: {
        color: "#7f7f7f",
        fontSize: "1rem",
        fontWeight: 700
      }
    }
  }
});

export default theme;
