import axios from 'axios';
import api from "../api";

export default function getBrandAnalytics(brandId, viewOptions) {
  const views = api.get(
    `/admin/brands/${brandId}/analytics?startDate=${viewOptions.startDate}&endDate=${viewOptions.endDate}&view=view_counts`
  );
  const claims = api.get(
    `/admin/brands/${brandId}/analytics?startDate=${viewOptions.startDate}&endDate=${viewOptions.endDate}&view=claim_counts`
  );
  
  return axios.all([views, claims]).then(axios.spread((...responses) => {

    const viewResponse = responses[0].data
    const claimResponse = responses[1].data
    return {
      views: viewResponse,
      claims: claimResponse
    }
  }))
}
