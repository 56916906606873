// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title, useAuthenticated } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import getFeedSourceContentAsync from "../common/getFeedSourceContent";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  }
});

const CreateContentButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/content/create",
      state: { record: record }
    }}
  >
    Create content
  </Button>
);

const FeedContentPage = ({ match }) => {
  const { params } = match;
  const { feedSourceId } = params;
  const classes = useStyles();

  // Protect
  useAuthenticated();

  const [feedSourceContent, setFeedSourceContent] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const getFeedSource = () =>
    getFeedSourceContentAsync(feedSourceId)
      .then(result => {
        setLoading(false);
        if (result.data) {
          setFeedSourceContent(result.data);
        } else {
          setFeedSourceContent([]);
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });

   useEffect(() => {
    setLoading(true);
    setError(null);
    getFeedSource();
  }, [feedSourceId]);

  if (loading || !feedSourceContent)
    return (
      <Card>
        <Title title="Feeds" />
        <CardContent>Loading</CardContent>
      </Card>
    );
  const { items } = feedSourceContent;
  if (error)
    return (
      <Card>
        <Title title="Feeds" />
        <CardContent>{error.message}</CardContent>
      </Card>
    );
  if (!items) {
    return (
      <Card>
        <Title title="Feeds" />
        <CardContent>There are no items in current feed</CardContent>
      </Card>
    );
  }
  return (
    <Card>
      <Title title="Feeds" />
      <CardContent>
        <Paper className={classes.root}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">URL</TableCell>
                <TableCell align="right">Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items
                .slice(0, items.length > 20 ? 20 : items.length)
                .map(row => (
                  <TableRow key={row.title}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="right">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={row.link}
                      >
                        {row.link}
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      <CreateContentButton
                        record={{
                          title: row.title,
                          content: row['content:encoded'] || row.content,
                          sourceUrl: row.link,
                          publishedAt: new Date(row.pubDate)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default FeedContentPage;
