import firebase from "./firebase";
import startSession from "./common/startSession";
import stopSession from "./common/stopSession";
import sessionInfo from "./common/sessionInfo";

const authProvider = {
  login: ({ username, password }) =>  {
    return new Promise(async (resolve, reject) => {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(username, password);
        const token = await firebase.auth().currentUser.getIdToken();
        await startSession(token);
        const { data } = await sessionInfo();
        const response = data;
        if (
          response.statusCode === 401 ||
          response.statusCode === 403 ||
          response.role !== "admin"
        ) {
          reject({
            redirectTo: "/no-access",
            message: "You don`t have access"
          });
        }
        return resolve(true);
      } catch (e) {
        return reject({
          redirectTo: "/no-access",
          message: e.message
        });
      }
    });
  },
  logout: () =>  {
    return new Promise(async (resolve, reject) => {
      try {
        await stopSession();
        return resolve();
      } catch (e) {
        return reject({
          redirectTo: "/no-access",
          message: e.message
        });
      }
    });
  },
  checkAuth: () =>  {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await sessionInfo().then(data => data.data);
        if (user) {
          return resolve(user);
        } 
          return reject({
            redirectTo: "/no-access"
          });
        
      } catch (e) {
        return reject({
          redirectTo: "/no-access",
          message: e.message
        });
      }
    });
  },
  checkError: ({ status }) =>  {
    if (status === 401 || status === 403) {
      return new Promise(async (resolve, reject) => {
        try {
          await stopSession();
        } catch (e) {
          return reject({
            redirectTo: "/no-access",
            message: e.message
          });
        }
      });
    }
    return Promise.resolve();
  },
  getPermissions: () =>  {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await sessionInfo().then(data => data.data);
        if (user && user.role && user.role === "admin") {
          return resolve(user.role);
        } 
          return reject({
            redirectTo: "/no-access",
            message: "You don`t have access"
          });
        
      } catch (e) {
        return reject({
          redirectTo: "/no-access",
          message: e.message
        });
      }
    });
  }
};

export default authProvider;