// eslint-disable-next-line
import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  Edit,
  SimpleShowLayout,
  Show,
  DateInput,
  DateField,
  BooleanField,
  BooleanInput,
  SelectInput,
  ImageField,
  ImageInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ArrayField,
  Create
} from "react-admin";
import Button from "@material-ui/core/Button";
import SubGenreInput from "../inputs/SubGenreInput";
import ComplexButton from "../layout/Button";
import askPasswordReset from "../common/askPasswordReset";

import formatImageInput from '../formatters/imageFormatter';

const UserReferenceShow = props => {
  return (
    <Show {...props} title=" ">
      <SimpleShowLayout>
        <TextField source="address.country" label="Country" />
        <TextField source="address.city" label="City" />
        <TextField source="address.city_district" label="City district" />
        <TextField source="address.street" label="Street" />
        <TextField source="address.house_number" label="House number" />
        <TextField source="referralCode" />
        <ImageField source="profileImageUrl" title="Profile Photo" />
        <ReferenceArrayField
          label="Claimed Offers"
          reference="offers"
          source="claimedOffers"
        >
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="Brands" reference="brands" source="brands">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="pets">
          <Datagrid>
            <TextField source="name" />
            <TextField source="birthDate" />
            <ArrayField source="vaccinations">
              <Datagrid>
                <TextField source="name" />
                <TextField source="type" />
                <TextField source="date" />
              </Datagrid>
            </ArrayField>
            <ArrayField source="medicalRecords">
              <Datagrid>
                <ImageField source="imageUrl" />
                <TextField source="note" />
                <TextField source="date" />
              </Datagrid>
            </ArrayField>
            <BooleanField source="isPublic" />
            <TextField source="animal" />
            <TextField source="gender" />
            <ArrayField source="weights">
              <Datagrid>
                <TextField source="bodyShape" />
                <TextField source="weight" />
                <TextField source="date" />
              </Datagrid>
            </ArrayField>
            <TextField source="size" />
            <TextField source="foodType" />
            <TextField source="height" />
            <TextField source="breed" />
            <TextField source="chipId" />
            <TextField source="vet.name" label="Vet name" />
            <TextField source="vet.phone" label="Vet phone" />
            <TextField
              source="vet.insurance.provider"
              label="Vet insurance provider"
            />
            <TextField
              source="vet.insurance.policyNumber"
              label="Vet insurance policy number"
            />
            <TextField
              source="vet.insurance.renewalDate"
              label="Vet insurance renewal date"
            />
            <TextField
              source="vet.healthPlan.provider"
              label="Vet Health Plan provider"
            />
            <TextField
              source="vet.healthPlan.policyNumber"
              label="Vet Health Plan policy number"
            />
            <TextField
              source="vet.healthPlan.renewalDate"
              label="Vet Health Plan renewal date"
            />
            <TextField
              source="vet.outOfHours.name"
              label="Vet out of hours name"
            />
            <TextField
              source="vet.outOfHours.telephoneNumber"
              label="Vet out of hours telephone number"
            />
            <ImageField source="photoUrl" title="Pet Photo" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
const userRoles = [
  { id: "admin", name: "admin" },
  { id: "user", name: "user" }
];
const userStatuses = [
  { id: "active", name: "active" },
  { id: "banned", name: "banned" }
];
const petTypes = [{ id: "dog", name: "dog" }, { id: "cat", name: "cat" }];
const petFoodTypes = [
  { id: "wet trays / pouches", name: "wet trays / pouches" },
  { id: "dry kibble", name: "dry kibble" },
  { id: "mix of wet and dry", name: "mix of wet and dry" },
  { id: "raw / frozen", name: "raw / frozen" },
  { id: "scraps", name: "scraps" }
];
const genderTypes = [
  { id: "male", name: "male" },
  { id: "female", name: "female" }
];
const AuditEventsButton = ({ label, record, onClick, showNotification }) => {
  return (
    <Button
      label={label}
      color="primary"
      component={Link}
      to={{
        pathname: `/users/${record.id}/audit-events`
      }}
    >
      Audit events
    </Button>
  );
};
export const UsersList = props => {
  return (
    <List {...props}>
      <Datagrid expand={<UserReferenceShow />}>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="lastLoginAt" label="Last Sign in Time" />
        <TextField source="role" />
        <DateField source="birthDate" />
        <TextField source="status" />
        <DateField showTime source="createdAt" />
        <DateField showTime source="updatedAt" />
        <BooleanField source="emailVerified" />
        <EditButton />
        <ComplexButton label="Reset password" onClick={askPasswordReset} />
        <AuditEventsButton label="Audit events" />
      </Datagrid>
    </List>
  );
};

export const UsersEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="password" />
      <TextInput source="referralCode" />
      <SelectInput choices={userRoles} source="role" />
      <DateInput source="birthDate" />
      <SelectInput source="status" choices={userStatuses} />
      <BooleanInput source="emailVerified" />
      <TextInput source="address.country" />
      <TextInput source="address.city_district" />
      <TextInput source="address.city" />
      <TextInput source="address.postcode" />
      <TextInput source="address.street" />
      <TextInput source="address.house_number" />
      <ImageInput
        source="profileImageUrl"
        label="Profile Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceArrayInput
        source="claimedOffers"
        reference="offers"
        label="Claimed Offers"
        allowEmpty
      >
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="brands"
        reference="brands"
        label="Brands"
        allowEmpty
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ArrayInput source="pets">
        <SimpleFormIterator>
          <TextInput disabled source="id" />
          <TextInput source="name" />
          <DateInput source="birthDate" />
          <ArrayInput source="vaccinations">
            <SimpleFormIterator>
              <TextInput source="name" />
              <SubGenreInput
                label="Type"
                optionValue="name"
                dependsOnValue="vaccination-types"
                source="type"
                optionText="name"
              />
              <DateInput source="date" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="medicalRecords">
            <SimpleFormIterator>
              <ImageInput
                source="imageUrl"
                label="Record Image"
                accept="image/*"
                format={formatImageInput}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source="note" />
              <DateInput source="date" />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput label="Is Public?" source="isPublic" />
          <SelectInput source="animal" choices={petTypes} />
          <SelectInput source="gender" choices={genderTypes} />
          <ArrayInput source="weights">
            <SimpleFormIterator>
              <NumberInput source="bodyShape" min="1" max="5" />
              <TextInput source="weight" />
              <DateInput source="date" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="size" />
          <SelectInput source="foodType" choices={petFoodTypes} />
          <NumberInput source="height" />
          <NumberInput source="chipId" min="100000000000000" max="999999999999999"/>
          <SubGenreInput
            optionValue="name"
            label="Breed"
            dependsOnValue="breeds"
            source="breed"
            optionText="name"
          />
          <TextInput source="vet.name" />
          <TextInput source="vet.phone" />
          <TextInput source="vet.insurance.provider" />
          <TextInput source="vet.insurance.policyNumber" />
          <DateInput source="vet.insurance.renewalDate" />
          <TextInput source="vet.healthPlan.provider" />
          <TextInput source="vet.healthPlan.policyNumber" />
          <DateInput source="vet.healthPlan.renewalDate" />
          <TextInput source="vet.outOfHours.name" />
          <TextInput source="vet.outOfHours.telephoneNumber" />
          <ImageInput
            source="photoUrl"
            label="Pet Photo"
            accept="image/*"
            format={formatImageInput}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const UsersCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="password" />
      <SelectInput choices={userRoles} source="role" />
      <DateInput source="birthDate" />
      <SelectInput source="status" choices={userStatuses} />
      <BooleanInput source="emailVerified" />
      <TextInput source="address.country" />
      <TextInput source="address.city_district" />
      <TextInput source="address.city" />
      <TextInput source="address.postcode" />
      <TextInput source="address.street" />
      <TextInput source="address.house_number" />
      <ImageInput
        source="profileImageUrl"
        label="Profile Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceArrayInput
        source="claimedOffers"
        reference="offers"
        label="Claimed Offers"
        allowEmpty
      >
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="brands"
        reference="brands"
        label="Brands"
        allowEmpty
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ArrayInput source="pets">
        <SimpleFormIterator>
          <TextInput disabled source="id" />
          <TextInput source="name" />
          <DateInput source="birthDate" />
          <ArrayInput source="vaccinations">
            <SimpleFormIterator>
              <TextInput source="name" />
              <SubGenreInput
                optionValue="name"
                label="Type"
                dependsOnValue="vaccination-types"
                source="type"
                optionText="name"
              />
              <DateInput source="date" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="medicalRecords">
            <SimpleFormIterator>
              <ImageInput
                source="imageUrl"
                label="Record Image"
                accept="image/*"
                format={formatImageInput}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source="note" />
              <DateInput source="date" />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput label="Is Public?" source="isPublic" />
          <SelectInput source="animal" choices={petTypes} />
          <SelectInput source="gender" choices={genderTypes} />
          <ArrayInput source="weights">
            <SimpleFormIterator>
              <NumberInput source="bodyShape" min="1" max="5" />
              <TextInput source="weight" />
              <DateInput source="date" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="size" />
          <SelectInput source="foodType" choices={petFoodTypes} />
          <NumberInput source="height" />
          <NumberInput source="chipId" min="100000000000000" max="999999999999999" />
          <SubGenreInput
            optionValue="name"
            label="Breed"
            dependsOnValue="breeds"
            source="breed"
            optionText="name"
          />
          <TextInput source="vet.name" />
          <TextInput source="vet.phone" />
          <TextInput source="vet.insurance.provider" />
          <TextInput source="vet.insurance.policyNumber" />
          <DateInput source="vet.insurance.renewalDate" />
          <TextInput source="vet.healthPlan.provider" />
          <TextInput source="vet.healthPlan.policyNumber" />
          <DateInput source="vet.healthPlan.renewalDate" />
          <TextInput source="vet.outOfHours.name" />
          <TextInput source="vet.outOfHours.telephoneNumber" />
          <ImageInput
            source="photoUrl"
            label="Pet Photo"
            accept="image/*"
            format={formatImageInput}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const UsersShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="phone" />
      <ImageField source="profileImageUrl" title="Profile Photo" />
      <TextField source="lastLoginAt" label="Last Sign in Time" />
      <TextField source="role" />
      <DateField source="birthDate" />
      <TextField source="status" />
      <DateField showTime source="createdAt" />
      <DateField showTime source="updatedAt" />
      <TextField source="referralCode" />
      <BooleanField source="emailVerified" />
      <ReferenceArrayField
        label="Claimed Offers"
        reference="offers"
        source="claimedOffers"
      >
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Brands" reference="brands" source="brands">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="address.country" label="Country" />
      <TextField source="address.city" label="City" />
      <TextField source="address.city_district" label="City district" />
      <TextField source="address.street" label="Street" />
      <TextField source="address.house_number" label="House number" />
      <ArrayField source="pets">
        <Datagrid>
          <TextField source="name" />
          <TextField source="birthDate" />
          <ArrayField source="vaccinations">
            <Datagrid>
              <TextField source="name" />
              <TextField source="type" />
              <TextField source="date" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="medicalRecords">
            <Datagrid>
              <ImageField source="imageUrl" />
              <TextField source="note" />
              <TextField source="date" />
            </Datagrid>
          </ArrayField>
          <BooleanField source="isPublic" />
          <TextField source="animal" />
          <TextField source="gender" />
          <ArrayField source="vaccinations">
            <Datagrid>
              <TextField source="bodyShape" />
              <TextField source="weight" />
              <TextField source="date" />
            </Datagrid>
          </ArrayField>
          <TextField source="size" />
          <TextField source="foodType" />
          <TextField source="height" />
          <TextField source="breed" />
          <TextField source="chipId" />
          <TextField source="vet.name" label="Vet name" />
          <TextField source="vet.phone" label="Vet phone" />
          <TextField
            source="vet.insurance.provider"
            label="Vet insurance provider"
          />
          <TextField
            source="vet.insurance.policyNumber"
            label="Vet insurance policy number"
          />
          <TextField
            source="vet.insurance.renewalDate"
            label="Vet insurance renewal date"
          />
          <TextField
            source="vet.healthPlan.provider"
            label="Vet health plan provider"
          />
          <TextField
            source="vet.healthPlan.policyNumber"
            label="Vet health plan policy number"
          />
          <TextField
            source="vet.healthPlan.renewalDate"
            label="Vet health plan renewal date"
          />
          <TextField
            source="vet.outOfHours.name"
            label="Vet out of hours name"
          />
          <TextField
            source="vet.outOfHours.telephoneNumber"
            label="Vet out of hours telephone number"
          />
          <ImageField source="photoUrl" title="Pet Photo" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
