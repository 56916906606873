// in src/MyLayout.js
import React from "react";
import { Layout, Sidebar, Menu, Notification } from "react-admin";
import AppBar from "./AppBar";

const MyLayout = props => (
  <Layout
    {...props}
    appBar={AppBar}
    sidebar={Sidebar}
    menu={Menu}
    notification={Notification}
  />
);

export default MyLayout;
