import React from 'react'
// import range from 'lodash/range'
// import last from 'lodash/last'
// import { generateDrinkStats } from '@nivo/generators'
// import { Defs } from '@nivo/core'
// import { area, curveMonotoneX } from 'd3-shape'
// import * as time from 'd3-time'
// import { timeFormat } from 'd3-time-format'
import { ResponsiveLine } from '@nivo/line'

const commonProperties = {
    // width: 900,
    // height: 400,
    margin: { top: 20, right: 20, bottom: 60, left: 80 },
    animate: true,
    enableSlices: 'x',
}

// const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
//     <g>
//         <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
//         <circle
//             r={size / 5}
//             strokeWidth={borderWidth}
//             stroke={borderColor}
//             fill={color}
//             fillOpacity={0.35}
//         />
//     </g>
// )

const TimeLineChart = ({ data, colors }) => ( <ResponsiveLine
        {...commonProperties}
        colors={colors}
        data={data}
        xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'day',
            useUTC: false
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
            type: 'linear',
            stacked: false,
        }}
        axisLeft={{
            format: e => Math.floor(e) === e && e, // Stop float values in the axis
            legendOffset: 12,
        }}
        axisBottom={{
            format: '%b %d',
            tickValues: 5,
        }}
        curve={'monotoneX'}
        enablePointLabel={true}
        // pointSymbol={CustomSymbol}
        pointSize={5}
        pointBorderWidth={1}
        pointBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
        }}
        useMesh={true}
        enableSlices={false}
    />
);
export default TimeLineChart;