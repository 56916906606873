import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  SimpleShowLayout,
  Show,
  RichTextField,
  SelectInput,
  ImageInput,
  ImageField,
  TopToolbar,
  CreateButton,
  ExportButton,
} from "react-admin";
import Button from "@material-ui/core/Button";
import TagsField from "../fields/TagsField";
import TagsInput from "../inputs/TagsInput";

import formatImageInput from '../formatters/imageFormatter';

// Quill
import Quill from 'quill';
import RichTextInput from "ra-input-rich-text";
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
// Register the module in the callback
Quill.register("modules/blotFormatter", BlotFormatter);

const BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style', 'data-align'];

// Override image embed of quill to allow style attr
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

// Content Page Components

const ContentActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/feeds"
      }}
    >
      Create using feed
    </Button>
  </TopToolbar>
);

const ContentReferenceShow = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <ImageField source="photoUrl" title="Photo" />
        <RichTextField source="content" />
      </SimpleShowLayout>
    </Show>
  );
};

export const ContentList = props => (
  <List {...props} actions={<ContentActions />}>
    <Datagrid expand={<ContentReferenceShow />}>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="updatedAt" />
      <TextField source="createdAt" />
      <TextField source="publishedAt" />
      <TagsField source="tags" />
      <TextField source="publisher" />
      <EditButton />
    </Datagrid>
  </List>
);
export const ContentEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="title" />
      <TagsInput />
      <RichTextInput source="content" options={{ modules: { toolbar:[
          [{ header: ['1', '2', '3', false] }, { 'color': [] }],
          ['bold', 'italic', 'underline', 'link', 'image'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean'],
        ], clipboard: { matchVisual: false }, blotFormatter: {} }}} />
      <SelectInput
        source="status"
        choices={[
          { id: "active", name: "active" },
          { id: "draft", name: "draft" }
        ]}
      />
      <SelectInput
        source="type"
        choices={[{ id: "article", name: "article" }]}
      />
      <ImageInput
        source="photoUrl"
        label="Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="publisher" />
    </SimpleForm>
  </Edit>
);
export const ContentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TagsInput />
      <RichTextInput source="content" options={{ modules: { toolbar:[
          [{ header: ['1', '2', '3', false] }, { 'color': [] }],
          ['bold', 'italic', 'underline', 'link', 'image'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean'],
        ], clipboard: { matchVisual: false }, blotFormatter: {} }}} />
      <SelectInput
        source="status"
        choices={[
          { id: "active", name: "active" },
          { id: "draft", name: "draft" }
        ]}
      />
      <SelectInput
        source="type"
        choices={[{ id: "article", name: "article" }]}
      />
      <ImageInput
        source="photoUrl"
        label="Image"
        accept="image/*"
        format={formatImageInput}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="publisher" />
    </SimpleForm>
  </Create>
);
export const ContentShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="updatedAt" />
      <TextField source="createdAt" />
      <TextField source="publishedAt" />
      <TagsField source="tags" />
      <ImageField source="photoUrl" title="Photo" />
      <RichTextField source="content" />
      <TextField source="publisher" />
    </SimpleShowLayout>
  </Show>
);
